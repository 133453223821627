import { useContext, useMemo } from "react"
import { userContext } from "../context/userContext"

export function useDisplayParticipants(participantList, users, owner) {
  const { currentUser } = useContext(userContext)

  return useMemo(() => {
    const filteredParticipants = participantList
      ?.filter(participant => participant.user_id !== currentUser)
      ?.map(participant => (
        users?.[participant?.user_id]?.name || `${owner?.roles?.[participant.role].display}`
      ))

    return filteredParticipants?.length ? filteredParticipants.join(', ') : ''
  }, [participantList, currentUser, owner, users])
}
