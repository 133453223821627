import React, { useCallback, useContext, useEffect, useState } from 'react'
import MessengerDrawer from '../features/views/Chat'
import ListView from '../features/views/Threads'
import NewThreadView from '../features/views/NewThread'
import { viewContext } from '../context/viewContext'
import { VIEWS } from '../features/views/views'
import { apiSlice, selectUnreadMessageCount, useGetInitialLoadQuery } from '../features/api/apiSlice'
import { userContext } from '../context/userContext'
import { useDispatch } from 'react-redux'
import { usePaginate } from '../common/usePaginate'
import { useBuildParams } from '../common/useBuildParams'

const MessengerWrapper = ({ page, setPage, setTotal, PaginationComponent, setActiveThreadId, activeThreadId, drawerOpen, setDrawerOpen }) => {
  const dispatch = useDispatch()
  const { currentView, unique_owner_id, threadsPerPage, setFocusId } = useContext(viewContext)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [messagesPage, setMessagesPage] = useState({})

  // close handler for drawer
  const handleClose = useCallback(() => {
    if (shouldRefetch) {
      dispatch(apiSlice.endpoints.getInitialLoad.initiate({ unique_owner_ids: [unique_owner_id], per_page: threadsPerPage, page: 1 }, { subscribe: false, forceRefetch: true }))
      setPage(1)
    }
    setShouldRefetch(false)
    setDrawerOpen(false)
    setActiveThreadId(null)
    setFocusId(null)
  }, [shouldRefetch, unique_owner_id])

  return (
    <div className='messenger-wrapper'>
      <div className='dome-relative'>
        {currentView === VIEWS.LIST_VIEW && <MessengerDrawer activeThreadId={activeThreadId} drawerOpen={drawerOpen} onClose={handleClose} shouldRefetch={shouldRefetch} setShouldRefetch={setShouldRefetch} threadPage={page} page={messagesPage} setPage={setMessagesPage} />}
        <div className={`messenger dome-light-shadow dome-rounded-border dome-full-width ${drawerOpen ? 'open' : ''}`}>
          {currentView === VIEWS.LIST_VIEW && <ListView setActiveThreadId={setActiveThreadId} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} page={page} setTotal={setTotal} PaginationComponent={PaginationComponent} setMessagesPage={setMessagesPage} />}
          {currentView === VIEWS.NEW_MESSAGE_VIEW && <NewThreadView page={page} />}
        </div>
      </div>
    </div>
  )
}

const MessengerToggle = ({ handleClick, page, unreadMessageCount }) => {
  const messageIconClasses = ['fal', 'fa-comments']
  messageIconClasses.push(unreadMessageCount > 0 ? 'dome-color-aqua' : '')
  const unreadCountText = unreadMessageCount > 9 ? `9+` : `${unreadMessageCount}`

  return (
    <div className='dome-d-flex dome-justify-end dome-align-center' style={{paddingInline: '17px', cursor: 'pointer'}} >
      <div className='dome-d-flex dome-gap3 dome-align-center' onClick={handleClick}>
        <i className={messageIconClasses.join(' ')} style={{fontSize: '18px'}}></i>
        {unreadMessageCount > 0 && <span className='dome-color-aqua'>{`${unreadCountText} new`}</span>}
      </div>
    </div>
  )
}


const App = () => {
  const { threadsPerPage, unique_owner_id, focusId } = useContext(viewContext)
  const { page, setPage, setTotal, Component: PaginationComponent } = usePaginate({ perPage: threadsPerPage })

  const queryParams = useBuildParams(page, focusId)
  const { data, unreadMessageCount, isLoading, message_threads } = useGetInitialLoadQuery(queryParams, {
    selectFromResult: (result) => ({
      ...result,
      unreadMessageCount: selectUnreadMessageCount(result, unique_owner_id),
      message_threads: result.data?.message_threads
    })
  })
  const [openMessenger, setOpenMessenger] = useState(false)
  const [activeThreadId, setActiveThreadId] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (message_threads && focusId in message_threads) {
      setOpenMessenger(true)
      setPage(data?.pagination.current_page)
      setDrawerOpen(true)
      setActiveThreadId(Number(focusId))
    }
  }, [message_threads, focusId])


  const handleClick = useCallback(() => {
    setOpenMessenger(!openMessenger)

    if (openMessenger) {
      dispatch(apiSlice.util.invalidateTags(['Message']))
    }
  }, [openMessenger, dispatch])

  if (isLoading && !unreadMessageCount) {
    return <div className='dome-d-flex dome-justify-end dome-align-center' style={{paddingInline: '17px', cursor: 'pointer'}}>
      <i className='fa-duotone fa-spinner fa-spin-pulse' />
    </div>
  }

  if (data) {
    return (
      <div className='dome-relative'>
        <MessengerToggle handleClick={handleClick} page={page} unreadMessageCount={unreadMessageCount} />
        {openMessenger &&
          <MessengerWrapper
            page={page}
            setPage={setPage}
            setTotal={setTotal}
            PaginationComponent={PaginationComponent}
            activeThreadId={activeThreadId}
            setActiveThreadId={setActiveThreadId}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />}
      </div>
    )
  }

}

export default App
